import { Feature } from 'src/.generated/Feature.EnterpriseWeb.model';
import { useTheme } from 'lib/context/ThemeContext';

// Components
import Component, { ComponentBackgroundVariants } from 'src/helpers/Component/Component';
import { CarouselDynamicReviewsTheme } from './CarouselDynamicReviews.theme';
import SingleButton from 'src/helpers/SingleButton/SingleButton';
import Headline from 'src/helpers/Headline/Headline';
import classNames from 'classnames';
import { getEnum } from 'lib/utils';
import RenderReviewSlider from './RenderReviewSlider.helper';
import SvgIcon from 'src/helpers/SvgIcon/SvgIcon';
import { AffiliateTypes, parseReviewFilterData, useReviewDetails } from 'lib/reviews/get-reviews';

import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { ChangeLocation } from 'src/helpers/Location/ChangeLocation';
import { useAffiliate } from 'lib/context/AffiliateContext';

type paginationStyle = 'numbers' | 'dots';

export type CarouselDynamicReviewsProps =
  Feature.EnterpriseWeb.RenewalByAndersen.Components.Reviews.CarouselDynamicReviews;

const CarouselDynamicReviews = (props: CarouselDynamicReviewsProps) => {
  const style = getEnum<ComponentBackgroundVariants>(props.fields?.backgroundColor) ?? 'gray';
  const { themeData } = useTheme(CarouselDynamicReviewsTheme(style));
  const paginationStyle = getEnum<paginationStyle>(props.fields?.carouselStyle) || 'dots';
  const loop = props.fields?.loop?.value || true;
  const autoplay = props.fields?.autoPlay?.value || false;
  const autoPlayInterval = props.fields?.interval?.value || 2000;
  const numberOfReviews = props.fields?.numberOfReviews?.value ?? 2;
  const minimumCharacters = props.fields?.minimumCharacters?.value ?? 100;
  const affiliateType = getEnum<string>(props.fields?.affiliate)?.toLowerCase() || '';
  const { userAffiliate, pageAffiliate } = useAffiliate();
  const reviews = useReviewDetails(
    parseReviewFilterData({
      reviewFilterProps: {
        ...props,
        pageSize: numberOfReviews,
        minimumCharacters: minimumCharacters,
      },
      userAffiliate: userAffiliate,
      pageAffiliate: pageAffiliate || undefined,
    })
  );

  const sliderSettings = {
    dots: true,
    enableNumberedPagination: paginationStyle == 'numbers',
    slidesToShow: 1,
    infinite: loop,
    autoplay: autoplay,
    autoplaySpeed: autoPlayInterval,
    pauseOnFocus: true,
    pauseOnHover: true,
    dotsClass: 'slick-dots static',
    adaptiveHeight: true,
  };

  return (
    <>
      <div className={themeData.classes.sectionWrapper}>
        <Headline classes={themeData.classes.headlineClass} {...props} />
        {affiliateType == AffiliateTypes?.userAffiliate?.toLowerCase() && <ChangeLocation />}
      </div>
      <div className={classNames('col-span-12')}>
        <Component
          variant="full"
          dataComponent="reviews/carouseldynamicreviews"
          gap="gap-x-0"
          backgroundVariant={style}
          {...props}
        >
          <SvgIcon icon="quote" className={themeData.classes.quoteClass} />
          <div className={classNames('carousel-slider col-span-12')}>
            <div className="carousel-slider">
              <RenderReviewSlider
                sliderSettings={sliderSettings}
                style={style}
                slidesData={reviews}
              />
            </div>
          </div>
        </Component>
      </div>
      <div className={themeData.classes.sectionWrapper}>
        <SingleButton classes={themeData.classes.buttonClass} {...props} />
      </div>
    </>
  );
};

export default withDatasourceCheck()<CarouselDynamicReviewsProps>(CarouselDynamicReviews);
