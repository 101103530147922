/* eslint-disable prettier/prettier */

import { ReviewDetails, ReviewFiltersRequest, ReviewSummary, SortBy } from './types';
import { Foundation } from '../../.generated/Foundation.EnterpriseWeb.model';
import { fetchInternalAPIWithQueryString } from 'lib/utils/api-request-utils';
import { Field, LayoutServiceData } from '@sitecore-jss/sitecore-jss-nextjs';
import { Affiliate } from 'lib/context/AffiliateContext';
import { GetStaticPropsContext } from 'next';
import { AFFILIATE_PREFIX, getAffiliateIdFromRewrite } from 'lib/affiliate/utils';
import { getEnum } from 'lib/utils';
import useSWR from 'swr';

const apiUrl =
typeof window === 'undefined'
? process.env.EW_ENTERPRISE_WEB_APIv2_URL
: '';

const isServerSide = typeof window === 'undefined';

/** For use with components that retrieve review data client-side. Utilizes SWR for efficient data retrieval and caching */
export const useReviewDetails = (filters: ReviewFiltersRequest): ReviewDetails[] | null => {
  if (!filters.productId && !filters.affiliateId) { // return early if no review filters
    return null;
  }
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(filters),
  };
  const fetcher = () => fetchInternalAPIWithQueryString(`${apiUrl}/api/rba/reviews/get-review-details/`,
    requestOptions
  ).then((res) => res.json());
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data } = useSWR(requestOptions.body, fetcher);
  return data;
};

/** For use with components that retrieve review data client-side. Utilizes SWR for efficient data retrieval and caching */
export const useReviewSummary = (filters: ReviewFiltersRequest): ReviewSummary | null => {
  if (!filters.productId && !filters.affiliateId) { // return early if no review filters
    return null;
  }
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(filters),
  };
  const fetcher = () => fetchInternalAPIWithQueryString(`${apiUrl}/api/rba/reviews/get-reviews-summary/`,
    requestOptions
  ).then((res) => res.json());
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data } = useSWR(requestOptions.body, fetcher);
  return data;
};

/** For use with components that retrieve review data server side (getStaticProps) */
export async function getReviewsDetails(filters: ReviewFiltersRequest): Promise<ReviewDetails[]> {

  if(!filters){
    return [];
  }

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(filters),
  };

  const response = await fetchInternalAPIWithQueryString(
    `${apiUrl}/api/rba/reviews/get-review-details/`,
    requestOptions
  )
  const responseData: ReviewDetails[] = await response?.json();

  return responseData;
}


/** For use with components that retrieve review data server side (getStaticProps) */
export async function getReviewsSummary(filters: ReviewFiltersRequest): Promise<ReviewSummary | null>  {
  if(!filters){
    return null;
  }

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(filters),
  };

  const response = await fetchInternalAPIWithQueryString(
    `${apiUrl}/api/rba/reviews/get-reviews-summary/`,
    requestOptions
  )
  const responseData: ReviewSummary = await response?.json();

  return responseData;
}

export type ReviewFilters = Foundation.EnterpriseWeb.RenewalByAndersen.FieldSets.Reviews.ReviewFilters & { pageSize: number; pageNumber: number; sortBy: SortBy; exactRating: number; minimumCharacters: number; };
export function parseReviewFilterData({ reviewFilterProps, userAffiliate, pageAffiliate, context, layout } : { reviewFilterProps: ReviewFilters, userAffiliate?: Affiliate, pageAffiliate?: Affiliate, context?: GetStaticPropsContext, layout?: LayoutServiceData }) {

  const parsedFilters: ReviewFiltersRequest = {
      productId: (reviewFilterProps.fields?.product?.fields?.reviewModCategory as Field<string>)?.value?.replace(/\s/g, ''),
      affiliateId: getAffiliateForReviewFilters(reviewFilterProps, userAffiliate, pageAffiliate, context, layout),
      minimumRating: reviewFilterProps.fields?.minimumRating?.name,
      containingText: reviewFilterProps.fields?.reviewsKeyword?.value,
      minimumCharacters: reviewFilterProps?.minimumCharacters ?? 0,
      pageSize: reviewFilterProps.pageSize,
      pageNumber: reviewFilterProps.pageNumber,
      sortBy: reviewFilterProps.sortBy,
    }

  if (reviewFilterProps.exactRating > 0) {
    parsedFilters.exactRating = reviewFilterProps.exactRating.toString();
  }

  return parsedFilters;
}

export const AffiliateTypes = {
  userAffiliate: 'User Affiliate',
  pageAffiliate: 'Page Affiliate'
};

function getAffiliateForReviewFilters(reviewFilterProps: Foundation.EnterpriseWeb.RenewalByAndersen.FieldSets.Reviews.ReviewFilters, userAffiliate?: Affiliate, pageAffiliate?: Affiliate, context?: GetStaticPropsContext, layout?: LayoutServiceData): string | undefined {
  const affiliateType = getEnum<string>(reviewFilterProps.fields?.affiliate);

  if (affiliateType !== AffiliateTypes.pageAffiliate && affiliateType !== AffiliateTypes.userAffiliate) {
    return (reviewFilterProps.fields?.affiliate?.fields?.affiliateId as Field<string>)?.value;
  }

  if (affiliateType === AffiliateTypes.userAffiliate) {
    if (!isServerSide) {
      return userAffiliate?.affiliateId;
    }
    const affiliatePath = (context?.params?.path as string[])?.find((x) => x.includes(AFFILIATE_PREFIX));
    return getAffiliateIdFromRewrite(affiliatePath || '');
  }

  if (affiliateType === AffiliateTypes.pageAffiliate) {
    return isServerSide ? layout?.sitecore?.context?.pageAffiliateId : pageAffiliate?.affiliateId;
  }

  return;
}
