import { useEffect } from 'react';
import { useRouter } from 'next/router';

declare global {
  interface Window {
    KAMPYLE_ONSITE_SDK?: {
      updatePageView?: () => void;
    };
  }
}
const KampyleScript = () => {
  const router = useRouter();

  useEffect(() => {
    const callUpdatePageView = () => {
      if (
        typeof window !== 'undefined' &&
        window.KAMPYLE_ONSITE_SDK &&
        typeof window.KAMPYLE_ONSITE_SDK.updatePageView === 'function'
      ) {
        console.log('Route changed! Calling updatePageView()');
        window.KAMPYLE_ONSITE_SDK.updatePageView();
      } else {
        console.log('KAMPYLE SDK not ready. Retrying...');
      }
    };

    // Call when component mounts
    callUpdatePageView();

    // Listen for route changes
    router.events.on('routeChangeComplete', callUpdatePageView);

    return () => {
      router.events.off('routeChangeComplete', callUpdatePageView);
    };
  }, [router]);

  return null;
};
export default KampyleScript;
