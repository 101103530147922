/***  Disabling no-explicit-any for whole file as this file is containing a whole lot of them,
 * and this utils are being used at multiple palces */

/* eslint-disable @typescript-eslint/no-explicit-any */

//import { memo } from 'react';
import { AttributeViewModelBase } from 'lib/renoworks';
import { RefObject, useEffect } from 'react';

export type RenderAttributeProps = {
  rendererMap: Map<string, React.FC<AttributeRendererProps<AttributeViewModelBase>>>;
  viewModel: AttributeViewModelBase;
  onUpdateOption: (option: any, collection?: any[]) => void;
  onUpdateOptionGroup: (optionGroup: any, collection?: any[]) => void;
  props?: any;
  selectedOptions?: any[];
  attributeIndex: number;
  maxAttributeIndex: number;
  modalRef?: RefObject<HTMLDivElement>;
};

export type AttributeRendererProps<VM extends AttributeViewModelBase> = {
  viewModel: VM;
  onUpdateOption?: (option: any, collection?: any[]) => void;
  onUpdateOptionGroup?: (optionGroup: any, collection?: any[]) => void;
  props?: any;
  selectedOptions?: any[];
  attributeIndex: number;
  maxAttributeIndex: number;
  modalRef?: RefObject<HTMLDivElement>;
};

export const RenderAttribute = (props: RenderAttributeProps): JSX.Element => {
  const { rendererMap, viewModel, ...rest } = props;

  // Always call useEffect, regardless of viewModel
  useEffect(() => {
    if (viewModel) {
      const isSummary = viewModel.component === 'SummaryAttribute';

      // URL manipulation
      if (window && window.location) {
        const currentUrl = window.location.href;
        const urlEndsWithSummary = currentUrl.endsWith('/summary');

        if (isSummary && !urlEndsWithSummary) {
          // If it's a summary page and URL doesn't end with /summary, append it
          window.history.replaceState({}, '', `${currentUrl}/summary`);
        } else if (!isSummary && urlEndsWithSummary) {
          // If it's not a summary page but URL ends with /summary, remove it
          const newUrl = currentUrl.substring(0, currentUrl.lastIndexOf('/summary'));
          window.history.replaceState({}, '', newUrl);
        }
      }
    }
  }, [viewModel]);

  if (!viewModel) {
    return <></>;
  }

  const attributeName = viewModel.component;
  const Element = rendererMap.get(attributeName);

  if (!Element) {
    return <>No renderer for {attributeName}</>;
  }

  return <Element viewModel={viewModel} {...rest} />;
};

export default RenderAttribute;
